import { createBrowserRouter, Navigate } from 'react-router-dom';
import MainLayout from '../../templates/MainLayout';
import Quiz from '../../pages/Quiz';
import ResultQuiz from '../../pages/ResultQuiz';
import CategoriesWords from '../../pages/CategoriesWords';
import LearnCategory from '../../pages/LearnCategory';

const browserRouter = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,

    children: [
      {
        path: '/categories',
        element: <CategoriesWords />,
      },
      {
        path: '/quiz/:id',
        element: <Quiz />,
      },
      {
        path: '/result-quiz',
        element: <ResultQuiz />,
      },
      {
        path: '/learn-category/:id',
        element: <LearnCategory />,
      },
      {
        path: '*',
        element: <Navigate to='/categories' replace />,
      },
      {
        path: '',
        element: <Navigate to='/categories' replace />,
      },
    ],
  },
]);

export default browserRouter;
