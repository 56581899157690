import ReactDOM from 'react-dom/client';
import './styles/tailwind.css';
import * as serviceWorker from './serviceWorker';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import browserRouter from './cors/router/browserRouter';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const root: HTMLElement = document.getElementById('root')!;

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={browserRouter} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
