const colors = {
  appBg: 'linear-gradient(135deg, #E0E7FF 0%, #C7D2FE 50%, #A5B4FC 100%)',
  textColor: '#1E293B',
  cardBg: '#FFFFFF',
  cardShadow: '0 4px 10px rgba(30, 41, 59, 0.1)',
  onCardTextColor: '#1E293B',
  onCardSpecialBg: '#93C5FD',
  onCardSpecialText: '#1E3A8A',
};

export default colors;
