interface IQuizVariant {
  onClick: () => void;
  isAnswered: boolean;
  isRight: boolean;
  isWrong: boolean;
  children: string;
}

export default function QuizVariant({
  onClick,
  isAnswered,
  isRight,
  isWrong,
  children,
}: IQuizVariant) {
  return (
    <button
      onClick={onClick}
      className={`w-full py-2 px-4 rounded border text-left ${
        isAnswered
          ? isRight
            ? 'bg-green-500 text-white'
            : isWrong
              ? 'bg-red-500 text-white'
              : 'bg-gray-200'
          : 'bg-gray-200 hover:bg-gray-300'
      }`}
      disabled={isAnswered}
    >
      {children}
    </button>
  );
}
