import axios from 'axios';
import { Category, Question } from '../../types';
import { CategoryWords } from '../../types/CategoryWords';

const service = axios.create({
  timeout: 3000,
  baseURL: process.env.REACT_APP_BASE_URL,
  maxRedirects: 2,
});

class Service {
  static getCategories = async (): Promise<Category[]> => {
    const result = await service.get<Category[]>('/categories');

    if (result.status !== 200) {
      throw Error();
    }

    return result.data;
  };

  static getCategoryWords = async (
    categoryId: string
  ): Promise<CategoryWords> => {
    const result = await service.get<CategoryWords>(
      `/categories/${categoryId}`
    );

    if (result.status !== 200) {
      throw Error();
    }

    return result.data;
  };

  static getTestCategory = async (categoryId: string): Promise<Question[]> => {
    const result = await service.get<Question[]>(
      `/exercise/words/${categoryId}`
    );

    if (result.status !== 200) {
      throw Error();
    }

    return result.data;
  };
}

export default Service;
