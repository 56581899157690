import { Outlet } from 'react-router-dom';
import colors from '../styles/colors';

export default function MainLayout() {
  return (
    <div
      style={{
        background: colors.appBg,
      }}
      className='min-h-screen min-w-full overflow-x-auto bg-blue-200 flex justify-center content-center'
    >
      <Outlet />
    </div>
  );
}
