import Card from '../molecules/Card';

interface QuizBottomPanelProps {
  rightCount: number;
  wrongCount: number;
  progressText: string;
}

export default function QuizBottomPanel({
  rightCount,
  wrongCount,
  progressText,
}: QuizBottomPanelProps) {
  return (
    <Card className='p-2 px-4 mt-8 flex justify-between items-center'>
      <div className='flex'>
        <div className='flex w-min gap-2 items-center bg-green-200 px-2 py-1.5 rounded rounded-r-none'>
          <div className='bg-green-500 h-6 w-6 rounded-full' />
          <div className='text-lg'>{rightCount}</div>
        </div>
        <div className='flex w-min gap-2 items-center bg-red-200 px-2 py-1.5 rounded rounded-l-none'>
          <div className='bg-red-500 h-6 w-6 rounded-full' />
          <div className='text-lg'> {wrongCount}</div>
        </div>
      </div>
      <div className='text-2xl'>{progressText}</div>
    </Card>
  );
}
