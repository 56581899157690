import { ReactNode } from 'react';
import QuizBottomPanel from '../organism/QuizBottomPanel';
import QuizTopPanel from '../organism/QuizTopPanel';
import { QuizTotals } from '../types';

interface QuizLayoutProps {
  children?: ReactNode;
  isStartQuiz: boolean;
  stateQuiz: QuizTotals;
  questionCount: number;
}

export default function QuizLayout({
  children,
  isStartQuiz,
  stateQuiz,
  questionCount,
}: QuizLayoutProps) {
  return (
    <div className='grid items-center  min-h-screen content-between w-full'>
      <QuizTopPanel isStartQuiz={isStartQuiz} />
      <div style={{ width: 'calc(100vw - 24px)' }} className='m-auto'>
        {children}
      </div>
      <QuizBottomPanel
        rightCount={stateQuiz.rightCount}
        wrongCount={stateQuiz.wrongCount}
        progressText={`${stateQuiz.answers.length}/${questionCount}`}
      />
    </div>
  );
}
