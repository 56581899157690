import Card from '../molecules/Card';
import ActionButton from '../molecules/ActionButton';
import { useNavigate } from 'react-router-dom';
import useTimer from '../utils/useTimer';

interface QuizTopPanelProps {
  isStartQuiz: boolean;
}

export default function QuizTopPanel({ isStartQuiz }: QuizTopPanelProps) {
  const navigate = useNavigate();
  const time = useTimer(isStartQuiz);

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Card className='py-4  mb-8 flex justify-between content-baseline items-center rounded-none'>
      <ActionButton
        icon='RollbackOutlined.svg'
        typeColor='gray'
        onClick={onBack}
      />
      <div className='text-xl'>{time}</div>
    </Card>
  );
}
