import { useMemo } from 'react';
import Card from '../molecules/Card';
import QuizVariant from '../molecules/QuizVariant';
import { Answer, Question } from '../types';

interface IQuizVariants {
  question: Question;
  setAnswer: (index: number) => void;
  selectedAnswer?: Answer;
}
export default function QuizVariants({
  question,
  setAnswer,
  selectedAnswer,
}: IQuizVariants) {
  const isAnswered = Boolean(selectedAnswer === 0 || selectedAnswer);
  const variants = useMemo(
    () => question.variants.map((option, index) => ({ option, id: index })),
    [question]
  );

  return (
    <Card>
      <h2 className='text-2xl font-bold mb-8'>{question.question}</h2>
      <div className='space-y-4'>
        {variants.map((option, index) => (
          <QuizVariant
            key={option.id}
            isAnswered={isAnswered}
            isRight={index === question.rightIndex}
            isWrong={selectedAnswer === index}
            onClick={() => setAnswer(index)}
          >
            {option.option}
          </QuizVariant>
        ))}
      </div>
    </Card>
  );
}
