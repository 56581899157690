import { useEffect, useLayoutEffect, useState } from 'react';
import QuizVariants from '../organism/QuizVariants';
import { useQuery } from 'react-query';
import { Question, QuizResult, Answer, QuizTotals } from '../types';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import Service from '../cors/service';
import QuizLayout from '../templates/QuizLayout';

const initQuizTotals: QuizTotals = {
  wrongCount: 0,
  rightCount: 0,
  answers: [],
};

export default function Quiz() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState<Question | null>();
  const [selectedAnswer, setSelectedAnswer] = useState<Answer>();
  const [quizTotals, setQuizTotals] = useState<QuizTotals>(initQuizTotals);

  const {
    isLoading,
    isFetching,
    error,
    data: questions,
  } = useQuery([], () => Service.getTestCategory(id!), {
    enabled: !!id,
    cacheTime: 0,
    staleTime: 0,
  });

  useLayoutEffect(() => {
    if (questions) {
      setCurrentQuestion(questions[0]);
      setSelectedAnswer(null);
      setQuizTotals(initQuizTotals);
    }
  }, [questions]);

  useEffect(() => {
    if (!selectedAnswer && selectedAnswer != 0) return;

    const timeout = setTimeout(() => {
      if (quizTotals.answers.length === questions?.length) {
        const totalQuiz: QuizResult = {
          answers: quizTotals.answers,
          questions,
        };
        navigate('/result-quiz', { state: totalQuiz });

        return;
      }

      setCurrentQuestion(questions![quizTotals.answers.length]);
      setSelectedAnswer(undefined);
    }, 1000);

    return () => clearInterval(timeout);
  }, [selectedAnswer]);

  const setAnswer = (newAnswer: number) => {
    const currentQuestion = questions![quizTotals.answers.length];
    const rightQuestion = currentQuestion.rightIndex;

    setQuizTotals((prev) => ({
      wrongCount:
        rightQuestion != newAnswer ? prev.wrongCount + 1 : prev.wrongCount,
      rightCount:
        rightQuestion == newAnswer ? prev.rightCount + 1 : prev.rightCount,
      answers: [...prev.answers, newAnswer],
    }));
    setSelectedAnswer(newAnswer);
  };

  if (error) return <Navigate to='/categories' replace />;

  const hideContent = isLoading || isFetching || !questions;

  return (
    <QuizLayout
      isStartQuiz={!!currentQuestion}
      stateQuiz={quizTotals}
      questionCount={questions?.length ?? 0}
    >
      {currentQuestion && !hideContent && (
        <QuizVariants
          selectedAnswer={selectedAnswer}
          question={currentQuestion!}
          setAnswer={setAnswer}
        />
      )}
    </QuizLayout>
  );
}
