import { useState } from 'react';

interface WordCardProps {
  rus: string;
  en: string;
}

export default function WordCard({ rus, en }: WordCardProps) {
  const [isBlur, setIsBlur] = useState(false);

  return (
    <div
      className='p-4 bg-white border border-gray-300 rounded-lg shadow hover:shadow-lg transition-shadow duration-300'
      onClick={() => setIsBlur((prev) => !prev)}
    >
      <h3
        style={{
          transition: '.5s',
          filter: `blur(${isBlur ? 5 : 0}px)`,
        }}
        className='text-xl font-semibold mb-2 text-center text-blue-600'
      >
        {en}
      </h3>
      <p className='text-lg text-center text-gray-700'>{rus}</p>
    </div>
  );
}
