import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Service from '../cors/service';
import CategoryWordCard from '../organism/CategoryWordCard';

export default function Categories() {
  const navigate = useNavigate();
  const { isLoading, error, data } = useQuery(
    'getCategories',
    Service.getCategories
  );

  const toTest = (id: string) => {
    navigate(`/quiz/${id}`);
  };

  const toLearn = (id: string) => {
    navigate(`/learn-category/${id}`);
  };

  if (!data || isLoading) return null;
  if (error) return <div>Error!</div>;

  return (
    <div className='p-6 w-full'>
      <h1 className='text-2xl font-bold mb-4'>Word Categories</h1>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {data.map((category) => (
          <CategoryWordCard
            key={category.id}
            category={category}
            toLearn={() => toLearn(category.id)}
            toTest={() => toTest(category.id)}
          />
        ))}
      </div>
    </div>
  );
}
