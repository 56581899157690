import { useState, useEffect } from 'react';

const useTimer = (isStart: boolean): string => {
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    if (!isStart) return;
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [isStart]);

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return formatTime(seconds);
};

export default useTimer;
