import { ReactNode } from 'react';
import colors from '../styles/colors';
import classNames from 'classnames';

interface CardProps {
  children: ReactNode;
  className?: string;
}
export default function Card({ children, className }: CardProps) {
  return (
    <div
      className={classNames(
        'p-8 rounded-lg shadow-md w-full w-md h-min',
        className
      )}
      style={{ background: colors.cardBg, boxShadow: colors.cardShadow }}
    >
      {children}
    </div>
  );
}
