import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QuizResult } from '../types';

export default function ResultQuiz() {
  const navigate = useNavigate();
  const location = useLocation();
  const { answers, questions }: QuizResult = location.state;

  const onFinish = () => {
    navigate('/categories');
  };

  return (
    <div className='bg-white p-8 rounded-lg shadow-md w-full max-w-md mx-auto'>
      <h2 className='text-2xl font-bold mb-6'>Your Results</h2>

      <div className='space-y-6 '>
        {questions.map((question, index) => (
          <div key={index} className='bg-gray-100 p-4 rounded-md'>
            <h3 className='text-lg font-semibold'>{question.question}</h3>
            <p
              className={`mt-2 ${answers[index] === question.rightIndex ? 'text-green-500' : 'text-red-500'}`}
            >
              Your Answer: {answers[index]}
            </p>
            {answers[index] !== question.rightIndex && (
              <p className='text-green-500'>
                Correct Answer: {question.variants[question.rightIndex]}
              </p>
            )}
          </div>
        ))}
      </div>

      <button
        onClick={onFinish}
        className='mt-6 w-full py-3 px-4 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600 transition-colors'
      >
        End Test
      </button>
    </div>
  );
}
