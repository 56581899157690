import ActionButton from '../molecules/ActionButton';
import { Category } from '../types';

interface CategoryWordCardProps {
  toTest: () => void;
  toLearn: () => void;
  category: Category;
}

export default function CategoryWordCard({
  category,
  toTest,
  toLearn,
}: CategoryWordCardProps) {
  return (
    <div className='category border border-gray-300 p-4 rounded-lg shadow bg-white'>
      <div>
        <h2 className='text-xl font-semibold'>{category.name}</h2>
      </div>
      <div className='flex space-x-2 mt-4 justify-end'>
        <ActionButton
          icon='ReadOutlined.svg'
          typeColor='green'
          onClick={toLearn}
        >
          Learn
        </ActionButton>
        <ActionButton icon='FormOutlined.svg' typeColor='blue' onClick={toTest}>
          Start test
        </ActionButton>
      </div>
    </div>
  );
}
