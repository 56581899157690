import Service from '../cors/service';
import { useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import WordCard from '../molecules/WordCard';
import ActionButton from '../molecules/ActionButton';

export default function LearnCategory() {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: words,
  } = useQuery(
    ['fetchData', id],
    () => Service.getCategoryWords(id as string),
    {
      enabled: id != null,
    }
  );

  if (!id || error) {
    return <Navigate to='/categories' replace />;
  }
  if (isLoading || !words) return null;

  const toTest = (id: string) => navigate(`/quiz/${id}`);
  const back = () => navigate('/categories');

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full p-6 pb-12'>
      <div className='flex justify-between'>
        <ActionButton
          icon='RollbackOutlined.svg'
          typeColor='gray'
          onClick={back}
        >
          Back
        </ActionButton>
        <ActionButton
          icon='FormOutlined.svg'
          typeColor='blue'
          onClick={() => toTest(id)}
        >
          Start test
        </ActionButton>
      </div>
      {words.content.map((word) => (
        <WordCard en={word.en} rus={word.rus} key={word.en} />
      ))}
    </div>
  );
}
