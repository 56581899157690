import classNames from 'classnames';
import React from 'react';

interface ActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: string;
  typeColor: 'green' | 'blue' | 'gray';
  icon: string;
}

export default function ActionButton({
  children,
  className,
  typeColor,
  icon,
  ...res
}: ActionButtonProps) {
  return (
    <button
      {...res}
      className={classNames(
        'flex items-center px-2 py-1.5 rounded',
        typeColor === 'blue' && 'bg-blue-500 text-white hover:bg-blue-600',
        typeColor === 'green' && 'bg-green-500 text-white hover:bg-green-600',
        typeColor === 'gray' && 'bg-gray-500 text-white hover:bg-gray-600'
      )}
    >
      {icon ? (
        <img
          alt='my-btn'
          src={`/icons/${icon}`}
          className={classNames('mr-2 h-5', className)}
        />
      ) : null}
      {children}
    </button>
  );
}
